<template>
  <div class="plugin-settings">
    <plugin-item-list-overview
      :isActive.sync="isActive"
      :pluginName="pluginName"
      :pluginDesc="pluginDesc"
      :pluginImgName="pluginImgName"
      :handle="handle"
      :can-edit="editPrivilege"
      has-modal-settings=true
      @open-modal="openModal"
      @update-is-active="updateActivationState"
    ></plugin-item-list-overview>
    <b-modal
      id="plugin-intro"
      content-class="plugin-modal"
      size="xl"
      centered
      ok-only
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      @ok="handleOk"
    >
      <template v-slot:modal-title>
        <h3>{{ $t('settings.plugins.intro.modalTitle') }}</h3>
      </template>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <p v-html="$t('settings.plugins.intro.modalDesc')"></p>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <h3 class="slide-header">
            {{ $t('settings.plugins.intro.headline') }}
            <b-dropdown
              toggle-class="dot-button"
              no-caret
              dropup
              no-flip
            >
              <template #button-content>
                <b-icon icon="three-dots-vertical" aria-hidden="true" scale="1.2"></b-icon>
              </template>
              <b-dropdown-item
                @click="$bvModal.show('modal-import')"
              >
                Import
                <b-icon icon="box-arrow-in-down" aria-hidden="true" scale=".8"></b-icon>
              </b-dropdown-item>
              <b-dropdown-item
                @click="$bvModal.show('modal-export')"
              >
                Export
                <b-icon icon="box-arrow-up" aria-hidden="true" scale=".8"></b-icon>
              </b-dropdown-item>
            </b-dropdown>
          </h3>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <div v-if="!settingsData.data.length">
            {{ $t('settings.plugins.intro.noSlides') }}
          </div>
          <div v-else>
            <plugin-intro-row-slide
              v-for="(rowData, index) in settingsData.data"
              :key="index"
              :configData="rowData"
              :index="index"
              :closeCollapsible="closeCollapsible"
              @update-setting-row="updateSettingRow"
              @open-rename-modal="openRenameModal"
              @delete-item="handleDeleteItem"
              @move-up="handleMoveUp"
              @move-down="handleMoveDown"
              @collapsible-closed="closeCollapsible = false"
            ></plugin-intro-row-slide>
            <div class="mt-4">
              <b-form-checkbox
                id="showButtonInNavigation"
                name="showButtonInNavigation"
                size="lg"
                v-model="showButtonInNavigation"
                @input="updateShowButtonInNavigation"
              >
                {{ $t('settings.plugins.intro.showButtonInNavi') }}
              </b-form-checkbox>
              <b-form-text
                v-html="$t('settings.plugins.intro.showButtonInNaviSubText')"
              ></b-form-text>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <b-col cols="12" offset-sm="1" sm="10">
          <b-button
            v-b-modal.modal-new-slide
            class="green-button-transparent mr-3 border-0"
          >
            <b-icon
              icon="plus-circle-fill"
              class="mr-2 top--3"
              aria-hidden="true"
              scale="1"
            ></b-icon>
            {{ $t('settings.plugins.intro.newButton') }}
          </b-button>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{ ok }">
        <b-button
          @click="ok()"
          block
          class="p-3"
        >
          {{ $t('button.ok') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-new-slide"
      ref="modal-new-slide"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
    >
      <template #modal-header>
        <h3>{{ $t('settings.plugins.intro.newSlide') }}</h3>
      </template>

      <template #default>
        <div>
          <div class="mt-5 mx-5 mb-3">
            <b-form-input
              autofocus
              debounce="500"
              v-model="newSlideHandle"
              @keyup.enter="handleAddNewSlide"
            >
            </b-form-input>
          </div>
        </div>
      </template>

      <template #modal-footer="{ close }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="p-3"
          block
          @click="handleAddNewSlide"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-rename-slide"
      ref="modal-rename-slide"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
    >
      <template #modal-header>
        <h3>{{ $t('settings.plugins.intro.renameHeader') }}</h3>
      </template>

      <template #default>
        <div>
          <div class="mt-5 mx-5 mb-3">
            <b-form-input
              autofocus
              debounce="500"
              v-model="slideHandleForRename"
              @keyup.enter="handleRenameSlide"
            >
            </b-form-input>
          </div>
        </div>
      </template>

      <template #modal-footer="{ close }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="p-3"
          block
          @click="handleRenameSlide"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-import"
      ref="modal-import"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
    >
      <template #modal-header>
        <h3>{{ $t('settings.plugins.intro.importHeader') }}</h3>
      </template>

      <template #default>
        <div>
          <div class="mt-4 mx-5 mb-3">
            <p v-html="$t('settings.plugins.intro.importDesc')"></p>
            <p v-if="showImportErrorMsg" class="error">
              {{ $t('settings.plugins.intro.importValidationMsg') }}
            </p>
            <b-form-textarea
              id="textarea-small"
              v-model="importData"
              rows="10"
            ></b-form-textarea>
          </div>
        </div>
      </template>

      <template #modal-footer="{ close }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="p-3"
          block
          @click="importUserData"
        >
          {{ $t('settings.plugins.intro.importButton') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-export"
      ref="modal-export"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
    >
      <template #modal-header>
        <h3>{{ $t('settings.plugins.intro.exportHeader') }}</h3>
      </template>

      <template #default>
        <div>
          <div class="mt-5 mx-5 mb-3">
            <div class="mb-2">
              Copy to clipboard:
              <span class="copy-data" @click="copyTextToClipboard">
                <b-icon icon="files" aria-hidden="true" scale="1.2"></b-icon>
              </span>
            </div>
            <pre>
{{ settingDataForExport }}
            </pre>
          </div>
        </div>
      </template>

      <template #modal-footer="{ ok }">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="ok()"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'PluginIntro',
  components: {
    PluginItemListOverview: () => import('@/components/settings/plugins/PluginItemListOverview.vue'),
    PluginIntroRowSlide: () => import('@/components/settings/plugins/PluginIntroRowSlide.vue'),
  },
  props: {
    pluginData: {
      type: Object,
      default: null,
    },
    editPrivilege: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    settingDataForExport() {
      return this.settingsData.data;
    },
  },
  data() {
    return {
      handle: 'plugin_intro',
      pluginName: this.$t('settings.plugins.intro.name'),
      pluginDesc: this.$t('settings.plugins.intro.desc'),
      pluginImgName: 'intro.jpg',
      showButtonInNavigation: false,
      newSlideHandle: '',
      slideHandleForRename: null,
      renameIndex: null,
      isActive: false,
      settingsData: {
        showButtonInNavigation: false,
        data: [],
      },
      importData: null,
      isCollapseOpen: false,
      showImportErrorMsg: false,
      closeCollapsible: false,
    };
  },
  created() {
    if (this.pluginData) {
      this.isActive = (this.pluginData && 'enabled' in this.pluginData)
        ? this.pluginData.enabled
        : this.isActive;
      this.settingsData = (
        this.pluginData
        && 'settings' in this.pluginData
        && this.pluginData.settings.data
        && Array.isArray(this.pluginData.settings.data)
        && this.pluginData.settings.data.length !== 0
      )
        ? cloneDeep(this.pluginData.settings)
        : [...this.settingsData];
      this.showButtonInNavigation = this.pluginData.settings.showButtonInNavigation;
    }
  },
  methods: {
    handleMoveUp(index) {
      this.closeCollapsible = true;
      const temp = this.settingsData.data[index];
      this.settingsData.data.splice(index, 1);
      this.settingsData.data.splice(index - 1, 0, temp);
    },
    handleMoveDown(index) {
      this.closeCollapsible = true;
      const temp = this.settingsData.data[index];
      this.settingsData.data.splice(index, 1);
      this.settingsData.data.splice(index + 1, 0, temp);
    },
    importUserData() {
      this.showImportErrorMsg = false;
      try {
        const validationDataArray = JSON.parse(this.importData);
        // check if array
        if (!Array.isArray(validationDataArray)) {
          throw new Error('not an array');
        }
        validationDataArray.forEach((object) => {
          if (
            !object.hasOwnProperty('text')
            || !object.hasOwnProperty('image')
            || !object.hasOwnProperty('handle')
            || !object.hasOwnProperty('buttonText')
          ) {
            throw new Error('property is missing');
          }
        });
        this.settingsData.data = validationDataArray;
        this.importData = null;
        this.$bvModal.hide('modal-import');
      } catch (e) {
        console.error('Import error: ', e);
        this.showImportErrorMsg = true;
      }
    },
    openRenameModal(index) {
      this.renameIndex = index;
      this.slideHandleForRename = this.settingsData.data[this.renameIndex].handle;
      this.$bvModal.show('modal-rename-slide');
    },
    handleRenameSlide() {
      this.$bvModal.hide('modal-rename-slide');
      this.settingsData.data[this.renameIndex].handle = this.slideHandleForRename;
      this.slideHandleForRename = null;
      this.renameIndex = null;
      this.updatePluginData();
    },
    openModal() {
      this.$bvModal.show('plugin-intro');
    },
    updateShowButtonInNavigation(val) {
      this.settingsData.showButtonInNavigation = val;
      this.updatePluginData();
    },
    updateSettingValue(value, handle) {
      if (handle in this.settingsData.data) {
        this.settingsData.data[handle] = value;
      }
    },
    copyTextToClipboard() {
      navigator.clipboard.writeText(JSON.stringify(this.settingsData.data));
      // display a message to the user for 2 seconds
      this.$bvToast.toast(this.$t('settings.plugins.intro.textCopied'), {
        title: this.$t('settings.plugins.intro.textCopiedTitle'),
        variant: 'success',
        solid: true,
        autoHideDelay: 2000,
      });
    },
    handleDeleteItem(index) {
      this.settingsData.data.splice(index, 1);
      this.updatePluginData();
    },
    handleOk() {
      this.updatePluginData();
    },
    handleAddNewSlide() {
      this.$bvModal.hide('modal-new-slide');
      // update the settings array
      this.settingsData.data.push({
        handle: this.newSlideHandle,
        image: '',
        text: '',
        buttonText: '',
      });
      this.newSlideHandle = '';
    },
    updateSettingRow(settingsRow, index) {
      this.settingsData.data[index] = settingsRow;
      this.updatePluginData();
    },
    updateActivationState(state) {
      this.isActive = state;
      if (state === true) {
        this.$bvModal.show('plugin-intro');
      }
      this.updatePluginData();
    },
    updatePluginData() {
      const pluginDataObject = {
        handle: this.handle,
        enabled: this.isActive,
        settings: this.settingsData,
      };
      this.$emit('update-plugin-data', pluginDataObject);
    },
  },
};
</script>

<style lang="scss" scoped>
#plugin-intro .slide-header /deep/ .btn-secondary.dot-button {
  background-color: transparent;
  border: none;
  color: #bbb;
  position: relative;
  top: -3px;
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}

.copy-data {
  cursor: pointer;

  /deep/ svg:hover {
    transition: all .2s;
    transform: scale(1.2);
  }
}

.error {
  color: red !important;
  padding: 5px 15px;
  border: solid 1px red;
}
</style>
